<template>
     <div class="buyList">
         <div class="search flex">
             <div class="flex" style="flex: 1;justify-content: end">
                 
                 <div class="search-item" style="white-space: nowrap">
                     <span>手机号码</span>
                     <a-input v-model="Mobile" style="width: 200px;margin: 0 20px;" placeholder="搜手机号码"></a-input>
                 </div>

                 <a-button style="margin-right: 20px;" @click="_reset()">重置</a-button>
                 <a-button type="primary" @click="_search">查询</a-button>
             </div>
         </div>
         <a-table  :pagination="false" v-if="dataList" :columns="columns" :data-source="dataList"   >
             <div slot="key"  slot-scope="text, record, index" >
                 <i class="iconfont icon-bianji icon-btn" @click="_setItem(record)"></i>
             </div>
         </a-table>
         <a-pagination v-if="total" style="margin-top: 35px;text-align: right;" @change="_page" :pageSize="Limit" :total="total"  show-less-items />



         <a-drawer
                 :title="infoData.StudentName+'('+infoData.UserName+')'"
                 width="520"
                 :visible="visible"
                 @close="onClose"
         >
               <div class="flex">
                   <div class="item">课程标题</div>
                   <a-select v-if="courseList" style="flex:1"  v-model="courseId">
                       <a-select-option v-for="item in courseList" :value="item.Id">
                           {{item.Title}}
                       </a-select-option>
                   </a-select>
               </div>

             <div
                     :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
             >
                 <a-button style="marginRight: 8px" @click="onClose">
                     取消
                 </a-button>
                 <a-popconfirm title="您确定要修改吗?" @confirm="_save()" okText="确定" cancelText="取消">
                     <a-button type="primary" >
                         确认
                     </a-button>
                 </a-popconfirm>

             </div>
         </a-drawer>
         
     </div>
</template>

<script>
    export default {
        name: "buyList",
        data(){
            return{
                Limit:10,
                Page:0,
                Mobile:'',
                dataList:'',
                columns :[
                    {
                        title: '会员姓名',
                        dataIndex: 'StudentName',
                        key: 'StudentName',
                    },
                    {
                        title: '手机号码',
                        dataIndex: 'UserName',
                        key: 'UserName',

                    },
                    {
                        title: '课程标题',
                        dataIndex: 'CourseTitle',
                        key: 'CourseTitle',

                    },
                    {
                        title: '费用',
                        dataIndex: 'Money',
                        key: 'Money',

                    },
                    {
                        title: '课节数',
                        dataIndex: 'LessonNum',
                        key: 'LessonNum',
                    },

                    {
                        title: '时间',
                        dataIndex: 'CreateTime',
                        key: 'CreateTime',

                    },

                    {
                        title: '操作',
                        dataIndex: 'key',
                        key: 'key',
                        align: 'center',
                        // width:'8%' ,
                        scopedSlots: { customRender: 'key' },
                    },
                ],
                total:'',
                infoData:'',
                courseId:'',
                courseList:'',
                visible: false,
            }
        },
        created(){
           this._buyList();
        },
        methods:{
            _buyList(){
                let self = this;
                this.$axios.get(1230,{
                    Limit:self.Limit,
                    Page:self.Page,
                    Mobile:self.Mobile,
                },res=>{
                    if(res.data.code==1){
                         self.dataList = res.data.data;
                         self.total  = res.data.total;
                    }else{
                        self.dataList = '';
                        self.total  = '';
                        this.$message.error(res.data.msg);
                    }
                })
            },
            _page(value){
                this.Page = value;
                this._buyList();
            },
            _search(){
                this._buyList();
            },
            _reset(){
                this.Mobile ='';
                this.Page ='';
                this._buyList();
            },
            _setItem(data){
               this.infoData = data;

               this.courseId = data.CourseId;
               let self = this;
               this.$axios.get(1231,{
               },res=>{
                   if(res.data.code==1){
                       self.visible = true;
                        self.courseList = res.data.data;
                   }
               })
            },
            onClose() {
                this.infoData = '';
                this.courseId ='';
                this.visible = false;
                this._buyList();
            },
            _save(){
                if(this.infoData.CourseId == this.courseId){
                    this.$message.error('请先选择要修改的内容');
                    return false;
                }
                let self = this;
                this.$axios.post(1232,{
                    Id:self.infoData.Id,
                    CourseId:self.courseId
                },res=>{
                    if(res.data.code==1){
                        self.onClose();
                        this.$message.success(res.data.msg);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .buyList{
        padding: 20px;
        height: 100%;
        background: #fff;
        border-radius: 10px;
    }
    .search{
        margin-bottom: 20px;
    }
    .item{
        width: 120px;
    }
</style>